import "../styles/Recrut.css";

function Recrut() {
  return (
    <div className="recrut">
      <p>
        Vous êtes DJ, Photographe, ou souhaitez proposer un lieu d'exception ?
        Rejoignez-nous dès maintenant et soyez parmi les premiers prestataires à
        figurer sur DIRD.
      </p>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfAF0p5KjmKkdD6u2XfVmr3e6d_LbXF2c0eNCcka9Dwcm2HLg/viewform?usp=sf_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Inscrivez-vous en avant-première
      </a>
    </div>
  );
}

export default Recrut;
