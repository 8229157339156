import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import "../styles/Banner.css";

function Banner() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`banner ${isScrolled ? "scrolled" : ""}`}>
      <img className="logo" src={logo} alt="Logo" />
      <h1>CRÉEZ VOTRE ÉVENEMENT,</h1>
      <h2>TROUVEZ LES MEILLEURS PRESTATAIRES.</h2>
    </div>
  );
}

export default Banner;
