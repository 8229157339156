import "../styles/Presentation.css";

function Presentation() {
  return (
    <div className="presentation">
      <header className="presentation-header">
        <h1>Qu'est ce que DIRD ?</h1>
      </header>
      <div>
        <p>
          DIRD est une plateforme innovante qui simplifie la mise en relation
          entre <strong> les organisateurs </strong> d'événements privés, tels
          que soirées, anniversaires ou mariages, et
          <strong> les prestataires </strong> passionnés qui feront de leur
          événement une expérience inoubliable.
        </p>
        <div className="user">
          <div className="organisateur">
            <h2>Pour les Organisateurs :</h2>
            <p>
              Trouvez les meilleurs prestataires dont vous avez besoins en un
              clic, visualisez et gérez l'intégralité de votre événement
              facilement.
            </p>
          </div>
          <div className="prestataire">
            <h2>Pour les Prestataires :</h2>
            <p>
              DIRD vous offre une visibilité unique auprès d'organisateurs
              d'événements privés. Rejoignez notre plateforme pour offrir vos
              services à des clients potentiels, élargir votre réseau et
              participer à des événements mémorables.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
