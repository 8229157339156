import "../styles/Footer.css";
import insta from "../assets/insta.png";

function Footer() {
  const afficherEmail = () => {
    const mailto = "mailto:contact@dird.fr";
    window.location.href = mailto;
  };

  return (
    <div className="footer">
      <div className="reseau">
        Suivez-nous sur les réseaux :
        <a href="https://www.instagram.com/dird.app/">
          <img src={insta} alt="reseau" />
        </a>
      </div>
      <div className="contact">
        <p>Vous avez des questions ?</p>
        <button onClick={afficherEmail}>Contactez-nous</button>
      </div>

      <div className="copyright">
        <div className="copyright-left">© Copyright : DIRD, 2024.</div>
        <div className="copyright-right">Réalisé par Romain Darde</div>
      </div>
    </div>
  );
}

export default Footer;
