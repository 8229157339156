import "./App.css";
import Presentation from "./components/Presentation";
import Banner from "./components/Banner";
import Recrut from "./components/Recrut";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Banner />
      <Presentation />
      <Recrut />
      <Footer />
    </div>
  );
}

export default App;
